import React, { useEffect } from "react";

const SuccessScreen = ({
   customerData,
   errors
}) => {
   useEffect(() => {
      window.scrollTo({ top: 450, behavior: 'smooth' })
   }, [])

   return (
      <React.Fragment>
         <h2>Your opt-out has been processed successfully</h2>

         {
            errors.email_address_submission ? (
               <div className="info-warning-box">
                  <span>
                     <h4>Notice</h4>
                     <p>According to our records the email address '{customerData.email_address}' is not currently opted-in to any email communications from Appliance Sure, so we've only processed your opt-out request for your phone number.</p>
                  </span>
               </div>
            ) : null
         }

         {
            errors.phone_number_submission ? (
               <div className="info-warning-box">
                  <span>
                     <h4>Notice</h4>
                     <p>According to our records the phone number '{customerData.phone_number}' is already on our Do Not Call (DNC) list, so we've only processed your opt-out request for your phone number.</p>
                  </span>
               </div>
            ) : null
         }

         <p>Opt-out requests are generally processed instantly, but in some cases where manual actions are required by a team member, your request can take up to 24 hours.</p>

         <p>To discuss your privacy or data protection further, please feel free to contact us at <a href="mailto:privacy@appliancesure.com">privacy@appliancesure.com</a></p>

         <br/>

         <a className="standard-button primary" href='/'>Go to homepage</a>
      </React.Fragment>
   )
}

export default SuccessScreen