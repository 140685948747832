import axios from "axios";
import React, { useState } from "react";
import { usePromiseTracker, trackPromise } from "react-promise-tracker";
import { RotatingLines } from "react-loader-spinner";
import ReCAPTCHA from "react-google-recaptcha";
import { detect } from 'detect-browser'
import Error from '../../../global/error'

const CustomerDetailsScreen = ({
   customerData,
   updateCustomerData,
   setStage,
   errors,
   setErrors
}) => {
   const SubmissionProgress = () => {
      const { promiseInProgress } = usePromiseTracker();

      return (
         promiseInProgress &&
         <div className="promise-tracker-container">
            <RotatingLines type="rotatingLines" strokeColor="#03989e" height={80} width={80}/>
            <h3>Submitting...</h3>
         </div>
      )
   }
   const [recaptchaVerified, setRecaptchVerified] = useState(false)

   const createDigitalFingerprint = async (fingerprint) => {
      const ip_lookup = await axios.get("https://geolocation-db.com/json/")
      const browser = detect();

      updateCustomerData({
         ...customerData,
         digital_fingerprint: {
            ip_address: ip_lookup.data.IPv4,
            location: `${ip_lookup.data.state}, ${ip_lookup.data.country_name}`,
            operating_system: browser.os,
            browser: browser.name,
            timestamp: (new Date()).toISOString(),
            recaptcha_fingerprint: fingerprint
         }
      })
   }

   const handleRobotVerification = (e) => {
      if(e === null) {
         setRecaptchVerified(false)
      } else {
         setRecaptchVerified(true)
         createDigitalFingerprint(e)
         setErrors({
            ...errors,
            recaptcha: false
         })
      }
   }

   const handleChange = (event) => {
      const name = event.target.name;
      let val = event.target.value;
      if(event.target.type === 'checkbox') val = event.target.checked
      const submission_type = event.target.dataset.type;

      updateCustomerData({
         ...customerData,
         [name]: val
      })

      setErrors({
         ...errors,
         [submission_type]: false,
         [name]: false,
         [`${submission_type}_submission`]: false,
         submission_error: false,
         select_one: false
      })
   }

   const phoneOptOut = async () => {
      const phone_optout = await axios({
         method: 'post',
         url: 'https://api.appliancesure.com/two/admin/leads/optOut/phone',
         data: {
            phone_number: customerData.phone_number,
            source: customerData.source,
            agent: 'N/A',
            digital_fingerprint: customerData.digital_fingerprint
         }
      })

      try {
         const response = await phone_optout.data

         return response
      }

      catch (err) {
         return {
            success: false
         }
      }
   }

   const emailOptOut = async () => {
      const email_optout = await axios({
         method: 'post',
         url: 'https://api.appliancesure.com/two/admin/leads/optOut/email',
         data: {
            email_address: customerData.email_address,
            source: customerData.source,
            agent: 'N/A',
            digital_fingerprint: customerData.digital_fingerprint
         }
      })

      try {
         const response = await email_optout.data

         return response
      }

      catch (err) {
         return {
            success: false
         }
      }
   }

   const handleDataValidation = () => {
      let errors_count = 0;
      let errors_object = {}

      const number_test = '1e28dd'
      console.log(parseInt(number_test))

      if (customerData.email_optout === false && customerData.phone_optout === false) {
         errors_count++
         errors_object.select_one = true
      } else {
         // Standard data validation
         const email = customerData.email_address
         if(customerData.email_optout === true && 
            (email.length < 6 || email.includes("@") === false || email.includes(".") === false)) {
               errors_count++
               errors_object.email_address = true
            }
         
         const phone = customerData.phone_number
         if(customerData.phone_optout === true) {
            const accepted_lengths = [9, 10, 11]
            
            if(accepted_lengths.includes(phone.length) === false) {
               errors_count++
               errors_object.phone_number = true
            }
         }

         if(recaptchaVerified === false) {
            errors_count++
            errors_object.recaptcha = true
         }
      }

      if(errors_count === 0) {
         return true
      } else {
         setErrors({
            ...errors,
            ...errors_object
         })

         return false
      }
   }

   const submitOptOut = async () => {
      if(handleDataValidation() === true) {
         trackPromise(
            new Promise( async (resolve, reject) => {
               let opt_outs = []
               let errors_count = 0
               let errors_object = {}
               
               setTimeout( async () => {
                  if(customerData.email_optout) {
                     const email = await emailOptOut()
                     opt_outs.push('email')
      
                     if(email.success === false) {
                        if(email.reason === 'This email address is not opted-in') {
                           errors_object.email_address_submission = true
                           errors_count++
                        } else {
                           errors_object.submission_error = true
                           errors_count++
                        }
                     }
                  }
      
                  if(customerData.phone_optout) {
                     const phone = await phoneOptOut()
                     opt_outs.push('phone')
      
                     if(phone.success === false) {
                        if(phone.reason === 'This number is already on the do not call register') {
                           errors_object.phone_number_submission = true
                           errors_count++
                        } else {
                           errors_object.submission_error = true
                           errors_count++
                        }
                     }
                  }

                  if(errors_count === 0) {
                     console.log(errors_count)
                     resolve(true)
                  } else {
                     setErrors({
                        ...errors,
                        ...errors_object
                     })
      
                     if(opt_outs.length <= errors_count) {
                        // Don't proceed
                        reject("Does not meet criteria")
                     } else if (errors_object.submission_error) {
                        // Don't proceed
                        reject("Does not meet criteria")
                     } else {
                        resolve(true)
                     }
                  }
               }, 1000)
            })
            .then(() => {
               setStage(2)
            })
            .catch(() => {
               // Don't go ahead
            })
         )
      }
      
   }

   return (
      <React.Fragment>
         <SubmissionProgress/>
         <h2 style={{marginTop: 0}}>Stop receiving communications</h2>

         <p>For one reason or another, we may have been in contact with you via email, post or telephone. If you are a customer of ours, we may still need to contact you to fulfil our obligations under our contract, but you can stop receiving other communications by filling in your details below:</p>

         <div className='checkbox-form-container' style={{marginTop: 30}}>
            <input
               type="checkbox"
               className='checkbox-trigger invisible'
               id="email_optout"
               name="email_optout"
               data-type="email_address"
               checked={customerData.email_optout}
               onChange={handleChange}
            />
            <label htmlFor='email_optout' className="checkbox negative"/>

            <span style={{textAlign: 'left'}}>
               <label htmlFor='email_optout' className='form-label'>Opt out of email communications</label>
            </span>
         </div>

         {
            customerData.email_optout ? (
               <React.Fragment>
                  <div style={{height: 30}}/>
                  <label htmlFor="email_address" className="form-label">Which email address would you like us to stop communicating with?</label>
                  <input
                     className="form-input"
                     type="text"
                     placeholder="Please enter your email address"
                     id="email_address"
                     name="email_address"
                     data-type="email_address"
                     value={customerData.email_address}
                     onChange={handleChange}
                  />
               </React.Fragment>
            ) : null
         }

         {
            errors.email_address ? (
               <Error message="Please enter a valid email address"/>
            ) : null
         }

         {
            errors.email_address_submission ? (
               <div className="info-warning-box" style={{marginTop: 0, marginBottom: 60}}>
                  <p>According to our records the email address '{customerData.email_address}' is not currently opted-in to any email communications from Appliance Sure.</p>
               </div>
            ) : null
         }

         <div className='checkbox-form-container' style={{marginTop: 30}}>
            <input
               type="checkbox"
               className='checkbox-trigger invisible'
               id="phone_optout"
               name="phone_optout"
               data-type="phone_number"
               checked={customerData.phone_optout}
               onChange={handleChange}
            />
            <label htmlFor='phone_optout' className="checkbox negative"/>

            <span style={{textAlign: 'left'}}>
               <label htmlFor='phone_optout' className='form-label'>Opt out of receiving phone calls</label>
            </span>
         </div>

         {
            errors.select_one ? (
               <React.Fragment>
                  <div style={{height: 40}}/>
                  <Error message="Please select at least one opt out to continue"/>
               </React.Fragment>
            ) : null
         }

         {
            customerData.phone_optout ? (
               <React.Fragment>
                  <div style={{height: 30}}/>
                  <label htmlFor="phone_number" className="form-label">Which phone number would you like us to stop calling?</label>
                  <input
                     className="form-input"
                     type="number"
                     placeholder="Please enter your phone number"
                     id="phone_number"
                     name="phone_number"
                     data-type="phone_number"
                     value={customerData.phone_number}
                     onChange={handleChange}
                  />
               </React.Fragment>
            ) : null
         }

         {
            errors.phone_number_submission ? (
               <div className="info-warning-box" style={{marginTop: 0, marginBottom: 60}}>
                  <p>According to our records the phone number '{customerData.phone_number}' is already on our Do Not Call (DNC) list.</p>
               </div>
            ) : null
         }

         {
            errors.phone_number ? (
               <Error message="Please enter a valid phone number"/>
            ) : null
         }



         <p style={{marginTop: 30}} className="disclaimer">Please note that it may take up to 24 hours for your details to be opted out of our system. If you would like to discuss a privacy concern, please email us at <a href="mailto:privacy@appliancesure.com">privacy@appliancesure.com</a></p>

         <br/>
         <br/>

         <ReCAPTCHA
            sitekey={process.env.GATSBY_RECAPTCHA_KEY}
            onChange={(e) => handleRobotVerification(e)}
         />

         {
            errors.recaptcha ? (
               <React.Fragment>
                  <div style={{height: 30}}/>
                  <Error message="Please verify you are a human"/>
               </React.Fragment>
            ) : null
         }

         <br/>

         <button className="standard-button primary" onClick={submitOptOut}>Submit preferences</button>
      </React.Fragment>
   )
   
}

export default CustomerDetailsScreen