import React from "react";
import { Helmet } from "react-helmet";
import Breadcrumbs from "../components/global/breadcrumbs";
import Footer from "../components/global/footer";
import Navigation from "../components/global/navigation";
import SEO from "../components/global/seo";
import OptOutForm from "../components/local/opt-out-form";

// CSS
import '../styles.css'

const OptOut = () => {
   return (
      <React.Fragment>
         <SEO
            title="Opt out of communications"
            slug="opt-out"
            metaDescription="Use our simple online form to opt out of email or telephone communications. Note, we still may need to contact you for contractual reasons."
            image=""
            titleTemplate="%s - Appliance Sure"
         />

         <Navigation/>

         <div className="content-hero-container">
            <div className="content-hero-content-container">
               <h1>Opt-out of communications</h1>
               <p>We're sorry to see you go, but if you'd like to cancel the policy that you have with us, please use this page to do so. Make sure you've checked the cancellation policy before submitting your request.</p>
            </div>
         </div>

         <div className="standard-container">
            <div className="content-split-container narrow-width">
               <div className="content-container">
                  <Breadcrumbs
                     location="opt-out"
                  />
                  
                  <OptOutForm />
               </div>
               <div className="side-bar-container">
                  <div className="side-bar-entries">
                  <span>
                     <input
                        type="checkbox"
                        defaultChecked={true}
                        id="related-articles"
                        className="related-articles-trigger invisible"
                     />

                     <label className="related-articles-label" htmlFor="related-articles">Important documents</label>

                     <div className="related-articles-content">
                        <a href="/privacy-policy">Privacy policy</a>
                        <a href="/terms-conditions">Terms & conditions</a>
                     </div>
                  </span>
                  </div>
               </div>
            </div>
         </div>

         <Footer/>
      </React.Fragment>
   )
}

export default OptOut