import React, { useEffect, useState } from "react";
import CustomerDetailsScreen from "./screens/customer-details";
import SuccessScreen from "./screens/success";

const OptOutForm = () => {
   useEffect(() => {
      window.scrollTo({ top: 0 })
   }, [])

   // States
   const [stage, setStage] = useState(1)
   const [customerData, updateCustomerData] = useState({
      email_optout: false,
      email_address: '',
      phone_optout: false,
      phone_number: '',
      source: 'Website',
      digital_fingerprint: {
         ip_address: '',
         location: '',
         operating_system: '',
         browser: '',
         timestamp: '',
         recaptcha_fingerprint: ''
      }
   })

   const [errors, setErrors] = useState({
      select_one: false,
      email_address: false,
      email_address_submission: false,
      phone_number: false,
      phone_number_submission: false,
      submission_error: false,
      recaptcha: false
   })

   switch (stage) {
      case 1:
         return <CustomerDetailsScreen
                  customerData={customerData}
                  updateCustomerData={updateCustomerData}
                  setStage={setStage}
                  errors={errors}
                  setErrors={setErrors}
               />
      
      case 2:
         return <SuccessScreen
                  customerData={customerData}
                  errors={errors}
               />
   }
}

export default OptOutForm